<template>
  <section v-if="data" class="o-seo-default">
    <div class="o-seo-default__background-image">
      <svg viewBox="0 0 1578 1433" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1058 0.195729C1147.48 0.228247 1230.18 47.9681 1274.95 125.425L1544.41 591.669C1589.16 669.102 1589.2 764.471 1544.51 841.857L1275.34 1308C1230.65 1385.38 1148.02 1433.03 1058.58 1433L519.996 1432.8C430.521 1432.77 347.818 1385.03 303.052 1307.57L33.5916 841.331C-11.1599 763.898 -11.1997 668.529 33.4874 591.143L302.662 125.004C347.349 47.6173 429.975 -0.0324898 519.422 1.66212e-05L1058 0.195729Z"
          fill="#F5F6FF"
        />
      </svg>
    </div>
    <div class="o-seo-default__container container">
      <h2 class="o-seo-default__title">{{ data.title }}</h2>
      <div class="o-seo-default__content">
        <div v-html="data.text.value" class="o-seo-default__text"></div>
        <div v-if="data.steps" class="o-seo-default__steps">
          <div v-if="data.steps.title" class="o-seo-default__steps-title">
            {{ data.steps.title }}
          </div>
          <div class="o-seo-default__steps-items">
            <div v-for="step in data.steps.items" class="o-seo-default__step">
              <div class="o-seo-default__step-title">{{ step.title }}</div>
              <div v-html="step.text.value" class="o-seo-default__step-text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOSeoDefault } from '~/components/O/Seo/Default/OSeoDefault.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schema
import schema from '~/components/O/Seo/Default/OSeoDefault.schema';

defineOptions({
  name: 'OSeoDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOSeoDefault>>(), {
  viewName: 'OSeoDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOSeoDefault>();

const data = ref<TOSeoDefault>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
